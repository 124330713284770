import { CHAIN_IDS } from 'utils/wagmi'
import ComingSoon from 'views/ComingSoon'
import { ChainId } from '@pancakeswap/sdk'
import Swap from '../views/Swap'

const SwapPage = () => {
  return <Swap />
}

SwapPage.chains = CHAIN_IDS

export default SwapPage
